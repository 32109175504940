import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  PageHeader,
  Input,
  Form,
  InputNumber,
  Divider,
  Typography,
  DatePicker,
  Modal,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { errorMsg } from "../../../Utils/Utils";
import moment from "moment";

const ReglementFacture = ({ facture, toPayeFacture }) => {
  const [form] = Form.useForm();
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [reste, setReste] = useState(0);
  const [warning, setWarning] = useState(false);
  const [saving, setSaving] = useState(false);
  const espece = { montant: reste, type: 0, note: "" };
  const cheque = {
    banque: "",
    date: 0,
    num: "",
    montant: reste,
    type: 1,
    note: "",
  };
  const traite = {
    banque: "",
    date: 0,
    num: "",
    montant: reste,
    type: 2,
    note: "",
  };

  useEffect(() => {
    if (null != facture) {
      var l__selected = [];
      facture.reglements.forEach((e) => {
        e.data.date = moment(e.data.date);
        e.data.old = true;
        e.data.id = e.id;
        l__selected.push(e.data);
      });
      form.setFieldsValue({
        reste: facture.d__reste.toFixed(3),
        montant: facture.d__totalFacture.toFixed(3),
      });
      setSelectedPayment(l__selected);
      setReste(facture.d__reste);
    }
    setSaving(false);
  }, [facture]);

  const deleteElement = (i) => {
    let items = [...selectedPayment];
    items[i]["montant"] = 0;
    items.splice(i, 1);
    setSelectedPayment(items);
    setReste(getReste(items));
  };

  const getReste = (items) => {
    setSelectedPayment(items);

    if (!facture) return 0;
    var value = (
      facture.d__reste -
      items
        .filter((e) => !e?.old)
        .reduce((a, b) => parseFloat(b.montant) + a, 0)
    ).toFixed(3);

    form.setFieldsValue({
      reste: value < 0 ? 0 : value,
      montant: facture.d__totalFacture.toFixed(3),
    });
    return value < 0 ? 0 : value;
  };

  const onChange = (i, attribut, value) => {
    let items = [...selectedPayment];
    if (items[i].type === 7 && value > facture.wallet) {
      value = facture.wallet;
    }

    items[i][attribut] = value;
    setSelectedPayment(items);
    setReste(getReste(items));
  };

  const validatePaiement = (reste) => {
    const resteValue = getReste(selectedPayment);

    if (verifyFields()) {
      setSaving(true);
      if (!reste && resteValue > 0) {
        setWarning(true);
        Modal.warning({
          okButtonProps: { style: { display: "none" } },

          content: (
            <div>
              <p>
                Voulez vous vraiment laisser le reste(
                {resteValue} ) pour le client!
              </p>
              <div style={{ textAlign: "center", marginTop: "2vh" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.destroyAll();
                    toPayeFacture(
                      selectedPayment.filter((el) => el.old == null),
                      true
                    );
                  }}
                >
                  Valider
                </Button>
                <Button
                  style={{ marginLeft: "2vh" }}
                  danger
                  onClick={() => {
                    Modal.destroyAll();
                    toPayeFacture(
                      selectedPayment.filter((el) => el.old == null),
                      false
                    );
                  }}
                >
                  Non
                </Button>
              </div>
            </div>
          ),
        });
      } else {
        toPayeFacture(
          selectedPayment.filter((el) => el.old == null),
          false
        );
      }
    } else {
      errorMsg("Merci de vérifier que tout les champs sont remplis!");
    }
  };

  const verifyFields = () => {
    var res = true;
    selectedPayment.map((element) => {
      switch (element.type) {
        case 0:
        case 3:
        case 4:
        case 5:
        case 6:
          if (element.montant == 0) {
            res = false;
          }
          break;
        case 1:
        case 2:
          if (
            element.montant == 0 ||
            element.banque == "" ||
            element.num == 0 ||
            element.date == "" ||
            element.nom == ""
          ) {
            res = false;
          }
          break;

        default:
          break;
      }
    });
    return res;
  };

  return (
    <div
      style={{
        marginLeft: "1vh",
        flex: 1,
        backgroundColor: "1px solid ",
      }}
    >
      <PageHeader>
        <Form form={form} layout="vertical">
          <Row justify="center">
            <Col span={6}>
              <Form.Item label="Montant total" name="montant">
                <Input readOnly={true} addonAfter="DT" />
              </Form.Item>
            </Col>
            <Col span={4} />
            <Col span={6}>
              <Form.Item label="Reste" name="reste" value={reste}>
                <Input
                  readOnly={true}
                  defaultValue={reste}
                  addonAfter="DT"
                  value="0"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PageHeader>
      <Row>
        <Col span={6}>
          <Card style={{ border: "0px solid transparent" }}>
            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() => {
                setReste(getReste([...selectedPayment, espece]));
              }}
            >
              Espèces
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() => setReste(getReste([...selectedPayment, cheque]))}
            >
              Chèque
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() => setReste(getReste([...selectedPayment, traite]))}
            >
              Traites
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() =>
                setReste(
                  getReste([...selectedPayment, { montant: 0, type: 3 }])
                )
              }
            >
              Carte bancaire
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() =>
                setReste(
                  getReste([...selectedPayment, { montant: 0, type: 4 }])
                )
              }
            >
              Virement bancaire
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              disabled={null != facture && facture.total < 1000}
              onClick={() =>
                setReste(
                  getReste([
                    ...selectedPayment,
                    {
                      montant: (facture.total * 99) / 100,
                      type: 5,
                    },
                  ])
                )
              }
            >
              Retenu à la source
            </Button>

            <Button
              onClick={() =>
                setReste(
                  getReste([...selectedPayment, { montant: 0, type: 6 }])
                )
              }
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
            >
              Contre remboursement
            </Button>

            <Button
              disabled={facture?.wallet === 0}
              onClick={() =>
                selectedPayment.filter((el) => el.type === 7).length === 0 &&
                setSelectedPayment([
                  ...selectedPayment,
                  {
                    montant: Math.min(facture.wallet, reste),
                    type: 7,
                  },
                ])
              }
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
            >
              Caisse{" " + facture.wallet}
            </Button>
          </Card>
        </Col>
        <Col span={16} style={{ overflowX: "auto", marginLeft: "2vh" }}>
          {selectedPayment.filter((e) => e.type === 0).length > 0 && (
            <>
              <Typography.Title level={5}> Espèces</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 0 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {element.old == null && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}

          {selectedPayment.filter((e) => e.type === 7).length > 0 && (
            <>
              <Typography.Title level={5}> Caisse</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 7 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {element.old == null && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={parseFloat(element.montant)}
                            formatter={(value) => `${element.montant}`}
                            disabled={element.old}
                            decimalSeparator={","}
                            max={facture.wallet}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 1).length > 0 && (
            <>
              <Typography.Title level={5}> Chèques</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Nom</th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Num</th>
                    <th className="ant-table-cell">Banque</th>
                    <th className="ant-table-cell">Date</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 1 && (
                      <tr>
                        <td className="ant-table-cell">
                          {element.old == null && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.nom}
                            onChange={(e) => onChange(i, "nom", e.target.value)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            decimalSeparator={","}
                            value={element.montant}
                            onChange={(e) => onChange(i, "montant", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            value={element.num}
                            onChange={(e) => onChange(i, "num", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.banque}
                            onChange={(e) =>
                              onChange(i, "banque", e.target.value)
                            }
                          />
                        </td>
                        <td className="ant-table-cell">
                          <DatePicker
                            value={element.date}
                            onChange={(e) => onChange(i, "date", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.note}
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 2).length > 0 && (
            <>
              <Typography.Title level={5}> Traites</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Nom</th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Num</th>
                    <th className="ant-table-cell">Banque</th>
                    <th className="ant-table-cell">Date</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 2 && (
                      <tr>
                        <td className="ant-table-cell">
                          {element.old == null && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.nom}
                            onChange={(e) => onChange(i, "nom", e.target.value)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            decimalSeparator={","}
                            value={element.montant}
                            onChange={(e) => onChange(i, "montant", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            value={element.num}
                            onChange={(e) => onChange(i, "num", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.banque}
                            onChange={(e) =>
                              onChange(i, "banque", e.target.value)
                            }
                          />
                        </td>
                        <td className="ant-table-cell">
                          <DatePicker
                            value={moment(element.date)}
                            onChange={(e) => onChange(i, "date", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 3).length > 0 && (
            <>
              <Typography.Title level={5}> Carte bancaire</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 3 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {element.old == null && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 4).length > 0 && (
            <>
              <Typography.Title level={4}> Virement bancaire</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 4 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {element.old == null && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 5).length > 0 && (
            <>
              <Typography.Title level={5}>Retenu à la source</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 5 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {element.old == null && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 6).length > 0 && (
            <>
              <Typography.Title level={5}>
                Contre remboursement
              </Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 6 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {element.old == null && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.length > 0 && (
            <Button
              type="primary"
              style={{ marginLeft: "1vh" }}
              disabled={saving}
              onClick={() => validatePaiement(false)}
            >
              valider
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ReglementFacture;
