import { Drawer, Button, Descriptions, Row, Col, Divider } from "antd";
import { useEffect } from "react";

const ProviderDetail = ({ visible, setDetailFournisseurVisible, provider }) => {
  useEffect(() => {}, [provider]);

  return (
    <Drawer
      title="Detail Fournisseur"
      width={"60%"}
      visible={visible}
      onClose={() => setDetailFournisseurVisible(false)}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button //onClick={exitCreateModal}
            style={{ marginRight: 8 }}
          >
            Annuler
          </Button>
        </div>
      }
    >
      <div style={{ scroll: "auto" }}>
        <Row>
          <Col span={24}>
            <>
              <Descriptions bordered size="large">
                <Descriptions.Item label="Nom" className="item-label">
                  {provider.s__nom}
                </Descriptions.Item>
                <Descriptions.Item label="Code" className="item-label">
                  {provider.s__code}
                </Descriptions.Item>
                <Descriptions.Item label="Téléphone">
                  {provider.s__phone}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {provider.s__email}
                </Descriptions.Item>
                <Descriptions.Item label="Registre de commerce">
                  {provider.s__register}
                </Descriptions.Item>

                <Descriptions.Item label="Fax">
                  {provider.s__fax}
                </Descriptions.Item>
                <Descriptions.Item label="Matricule fiscale">
                  {provider.s__matricule}
                </Descriptions.Item>
              </Descriptions>
              <Divider>Responsables</Divider>
              <Descriptions bordered size="large">
                {provider.responsables &&
                  provider.responsables.map((responsable, i) => (
                    <>
                      <Descriptions.Item label="Nom Responsable">
                        {responsable.nameResp}
                      </Descriptions.Item>
                      <Descriptions.Item label="Téléphone Responsable">
                        {responsable.phone}
                      </Descriptions.Item>
                    </>
                  ))}
              </Descriptions>
            </>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};
export default ProviderDetail;
