import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  PageHeader,
  Row,
  Typography,
} from "antd";
import moment from "moment";
import { useState } from "react";

const WalletModal = ({ visible, toSave }) => {
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [reste, setReste] = useState(0);
  const [saving, setSaving] = useState(false);
  const espece = { montant: reste, type: 0, note: "" };
  const cheque = {
    banque: "",
    date: 0,
    num: "",
    montant: reste,
    type: 1,
    note: "",
  };
  const traite = {
    banque: "",
    date: 0,
    num: "",
    montant: reste,
    type: 2,
    note: "",
  };

  const getReste = (items) => {
    setSelectedPayment(items);
  };

  const deleteElement = (i) => {
    let items = [...selectedPayment];
    items[i]["montant"] = 0;
    items.splice(i, 1);
    setSelectedPayment(items);
    getReste(items);
  };

  const onChange = (i, attribut, value) => {
    let items = [...selectedPayment];
    items[i][attribut] = value;
    setSelectedPayment(items);
    setReste(getReste(items));
  };

  const validate = () => {
    setSaving(true);
    toSave(selectedPayment);
  };

  return (
    <Modal
      title="Ajouter"
      visible={visible}
      className="modal-large"
      footer={[]}
    >
      <PageHeader>
        <h3>
          Total:
          <Input
            style={{ width: "30%", marginLeft: "20px" }}
            readOnly={true}
            value={selectedPayment.reduce(
              (a, b) => parseFloat(b.montant) + a,
              0
            )}
            addonAfter="DT"
          />
        </h3>
      </PageHeader>

      <Row>
        <Col span={6}>
          <Card style={{ border: "0px solid transparent" }}>
            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() => {
                getReste([...selectedPayment, espece]);
              }}
            >
              Espèces
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() => getReste([...selectedPayment, cheque])}
            >
              Chèque
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() => getReste([...selectedPayment, traite])}
            >
              Traites
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() =>
                getReste([...selectedPayment, { montant: 0, type: 3 }])
              }
            >
              Carte bancaire
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() =>
                getReste([...selectedPayment, { montant: 0, type: 4 }])
              }
            >
              Virement bancaire
            </Button>
          </Card>
        </Col>
        <Col span={16} style={{ overflowX: "auto", marginLeft: "2vh" }}>
          {selectedPayment.filter((e) => e.type === 0).length > 0 && (
            <>
              <Typography.Title level={5}> Espèces</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 0 && (
                      <tr>
                        <td className="ant-table-cell">
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => deleteElement(i)}
                          />
                        </td>

                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 1).length > 0 && (
            <>
              <Typography.Title level={5}> Chèques</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Nom</th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Num</th>
                    <th className="ant-table-cell">Banque</th>
                    <th className="ant-table-cell">Date</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 1 && (
                      <tr>
                        <td className="ant-table-cell">
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => deleteElement(i)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.nom}
                            onChange={(e) => onChange(i, "nom", e.target.value)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            decimalSeparator={","}
                            value={element.montant}
                            onChange={(e) => onChange(i, "montant", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            value={element.num}
                            onChange={(e) => onChange(i, "num", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.banque}
                            onChange={(e) =>
                              onChange(i, "banque", e.target.value)
                            }
                          />
                        </td>
                        <td className="ant-table-cell">
                          <DatePicker
                            value={element.date}
                            onChange={(e) => onChange(i, "date", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.note}
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 2).length > 0 && (
            <>
              <Typography.Title level={5}> Traites</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Nom</th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Num</th>
                    <th className="ant-table-cell">Banque</th>
                    <th className="ant-table-cell">Date</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 2 && (
                      <tr>
                        <td className="ant-table-cell">
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => deleteElement(i)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.nom}
                            onChange={(e) => onChange(i, "nom", e.target.value)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            decimalSeparator={","}
                            value={element.montant}
                            onChange={(e) => onChange(i, "montant", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            value={element.num}
                            onChange={(e) => onChange(i, "num", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.banque}
                            onChange={(e) =>
                              onChange(i, "banque", e.target.value)
                            }
                          />
                        </td>
                        <td className="ant-table-cell">
                          <DatePicker
                            value={moment(element.date)}
                            onChange={(e) => onChange(i, "date", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 3).length > 0 && (
            <>
              <Typography.Title level={5}> Carte bancaire</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 3 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => deleteElement(i)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 4).length > 0 && (
            <>
              <Typography.Title level={4}> Virement bancaire</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 4 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => deleteElement(i)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 5).length > 0 && (
            <>
              <Typography.Title level={5}>Retenu à la source</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 5 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => deleteElement(i)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 6).length > 0 && (
            <>
              <Typography.Title level={5}>
                Contre remboursement
              </Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Note</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 6 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => deleteElement(i)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            onChange={(e) =>
                              onChange(i, "note", e.target.value)
                            }
                            value={element.note}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.length > 0 && (
            <Button
              type="primary"
              style={{ marginLeft: "1vh" }}
              //disabled={saving}
              onClick={() => validate(false)}
            >
              valider
            </Button>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default WalletModal;
