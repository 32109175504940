import CAxios from "../../../Utils/CAxios";

export const toAddTransactionsAction = async (clientId, items) => {
  const { data } = await CAxios.post(`/api/wallet/transaction/add`, {
    clientId,
    data: items,
  });
  return data.success && data.success.data ? data.success.data : [];
};

export const toGetWalletDetailsAction = async (clientId) => {
  const { data } = await CAxios.post(`/api/wallet/details`, { clientId });
  return data.success && data.success.details ? data.success.details : null;
};

export const toRemoveTransaction = async (transactionId) => {
  const { data } = await CAxios.post(`/api/wallet/transaction/remove`, {
    transactionId,
  });
  return data.success && data.success.remove ? data.success.remove : false;
};
