import { Button, Col, Descriptions, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import cookie from "react-cookies";
import DataTable from "react-data-table-component";
import { BiTrash } from "react-icons/bi";
import { errorMsg, successMsg, toConvertDate } from "../../../Utils/Utils";
import {
  toAddTransactionsAction,
  toGetWalletDetailsAction,
  toRemoveTransaction,
} from "./walletApis";
import WalletModal from "./WalletModal";

const Wallet = ({ clientId }) => {
  const [balance, setBalance] = useState(0);
  const [toAdded, setToAdd] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };
  useEffect(() => {
    getWalletDetails(clientId);
  }, [clientId]);
  const getWalletDetails = (clientId) => {
    toGetWalletDetailsAction(clientId).then((res) => {
      if (res) {
        setBalance(res.balance);
        setTransactions(res.transactions);
      }
    });
  };
  const columns = [
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1.5,

      format: (row) => `${toConvertDate(row.createdDate)}`,
    },
    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Type",
      selector: "ref",
      format: (bl) => getReglementType(bl),
    },

    {
      name: "Détails",
      selector: "id",
      grow: 6,
      format: (reglement) => (
        <Descriptions
          column={6}
          bordered
          size="small"
          layout={
            reglement.type == 1 || reglement.type == 2
              ? "vertical"
              : "horizontal"
          }
        >
          {reglement.type == 1 || reglement.type == 2 ? (
            <>
              <Descriptions.Item label="Num" className="item-label">
                {reglement.data.num}
              </Descriptions.Item>
              <Descriptions.Item label="Nom" className="item-label">
                {reglement.data.nom}
              </Descriptions.Item>
              <Descriptions.Item label="Date" className="item-label">
                {toConvertDate(reglement.data.date)}
              </Descriptions.Item>
              <Descriptions.Item label="Banque" className="item-label">
                {reglement.data.banque}
              </Descriptions.Item>
              <Descriptions.Item label="Montant" className="item-label">
                {reglement.data.montant}
              </Descriptions.Item>
              <Descriptions.Item label="Note" className="item-label">
                {reglement.data.note}
              </Descriptions.Item>
            </>
          ) : (
            <>
              <Descriptions.Item label="Montant" className="item-label">
                {reglement.data.montant}
              </Descriptions.Item>
              <Descriptions.Item label="Note" className="item-label">
                {reglement.data.note}
              </Descriptions.Item>

              {reglement.reste && (
                <Descriptions.Item
                  label="Reste laissé pour le client"
                  className="item-label"
                >
                  {reglement.reste}
                </Descriptions.Item>
              )}
            </>
          )}
        </Descriptions>
      ),
    },

    {
      selector: "id",
      right: true,
      grow: 1,

      format: (reglement) =>
        cookie.load("removeReglement") == "true" && (
          <Popconfirm
            title={`Voulez vous vraiment supprimer cette transaction?`}
            onConfirm={() => deleteTransaction(reglement.id)}
            okText="Oui"
            placement="left"
            cancelText="Non"
          >
            <Button type="link" icon={<BiTrash />} />
          </Popconfirm>
        ),
    },
  ];

  const deleteTransaction = (i__id) => {
    toRemoveTransaction(i__id).then(() => {
      getWalletDetails(clientId);
    });
  };
  const getReglementType = (reglement) => {
    switch (reglement.type) {
      case 0:
        return "Espèces";
      case 1:
        return "Chèques";

      case 2:
        return "Traites";

      case 3:
        return "Carte bancaire";

      case 4:
        return "Virement bancaire";

      case 6:
        return "Contre remboursement";

      default:
        break;
    }
  };

  const toAddTransactions = (items) => {
    toAddTransactionsAction(clientId, items).then((res) => {
      if (res) {
        successMsg("Eléments ajoutées avec succès");
        setToAdd(false);
        getWalletDetails(clientId);
      } else {
        errorMsg("Erreur lors de l'ajout des éléments");
      }
    });
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      <Row>
        <Col span="16">
          <h3>Wallet</h3>
        </Col>
        <Col span="8" style={{ justifyContent: "end", display: "flex" }}>
          <h2>
            Caisse: <u>{balance.toFixed(2)}</u>
          </h2>
        </Col>
      </Row>
      <Row justify="end" style={{ margin: "1rem" }}>
        <Button title="Ajouter" type="primary" onClick={() => setToAdd(true)}>
          Ajouter
        </Button>
      </Row>

      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={transactions}
        pagination
        noHeader
        striped
        selectableRowsHighlight
        fixedHeader
        persistTableHead
        noDataComponent={<span>Pas de réglements trouvés</span>}
        contextMessage={{
          singular: "élément",
          plural: "éléments",
          message: "séléctionné(s)",
        }}
        paginationTotalRows={transactions.length}
      />

      <WalletModal
        visible={toAdded}
        toSave={(items) => toAddTransactions(items)}
      />
    </div>
  );
};
export default Wallet;
