import {
  Form,
  Modal,
  Col,
  Image,
  Row,
  Button,
  Card,
  Input,
  InputNumber,
  Select,
  Switch,
  Divider,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useState, useEffect } from "react";
import { errorMsg, successMsg } from "../../Utils/Utils";
import { toAddOrUpdateEntity, toGetEntityList, toRegisterUser } from "./Api";
const { Option } = Select;

const UserModal = ({ userModalVisible, cancelCreateModal, id, user }) => {
  const [form] = Form.useForm();
  const [Entitys, setEntitys] = useState([]);
  const [entityToAdd, setEntityToAdd] = useState("");
  const [state, setState] = useState({
    removeDuplicata: false,
    changeDate: false,
    removeReglement: false,
    caisse: false,
    inventory: false,
  });
  useEffect(() => {
    toGetEntityList().then((res) => {
      setEntitys(res);
    });
    setState({
      removeDuplicata: user.removeDuplicata,
      changeDate: user.changeDate,
      removeReglement: user.removeReglement,
      caisse: user.caisse,
      inventory: user.inventory,
    });
    form.setFieldsValue(user);
  }, [user]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const closeCreateModal = () => {
    form
      .validateFields()
      .then((values) => {
        values.id = user.id;
        values.options = [];

        toRegisterUser(values).then((res) => {
          cancelCreateModal(res);
        });
      })
      .catch((errorInfo) => {
        errorMsg(
          errorInfo["errorFields"][0]["name"] +
            " " +
            errorInfo["errorFields"][0]["errors"]
        );
      });
  };

  const addItemEntity = () => {
    toAddOrUpdateEntity(entityToAdd, -1).then((e) => {
      successMsg("Entité ajoutée avec succées!");
      setEntitys([...Entitys, e]);
      setEntityToAdd("");
    });
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      title={
        null != user.id
          ? "Modifier utilisateur"
          : "Ajouter un nouveau utilisateur"
      }
      visible={userModalVisible}
      maskClosable={false}
      onCancel={(e) => cancelCreateModal()}
      footer={[
        <Button key="back" onClick={(e) => cancelCreateModal()}>
          Annuler
        </Button>,
        <Button key="submit" type="primary" onClick={(e) => closeCreateModal()}>
          Enregistrer user
        </Button>,
      ]}
      onOk={closeCreateModal}
    >
      <Card>
        <Row span={24}>
          <Col span={8}>
            <Image width={400} src="/images/profile.png" />
          </Col>
          <Col span={16}>
            <Form form={form} {...layout}>
              <Form.Item
                rules={[{ required: true, message: "Champ obligatoire" }]}
                span={8}
                name="username"
                label="Username"
              >
                <Input />
              </Form.Item>
              {null == user.id && (
                <Form.Item
                  rules={[{ required: true, message: "Champ obligatoire" }]}
                  span={8}
                  name="password"
                  label="Mot de passe"
                >
                  <Input type="password" />
                </Form.Item>
              )}
              <Form.Item
                rules={[{ required: true, message: "Champ obligatoire" }]}
                span={8}
                name="nom"
                label="Nom"
              >
                <Input />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Champ obligatoire" }]}
                span={8}
                name="prenom"
                label="Prénom"
              >
                <Input />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Champ obligatoire" }]}
                span={8}
                name="phone"
                label="Téléphone"
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Champ obligatoire" }]}
                span={8}
                name="cin"
                label="CIN"
              >
                <InputNumber />
              </Form.Item>
              <Form.Item span={8} name="email" label="Email">
                <Input />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Champ obligatoire" }]}
                span={8}
                label="Role"
                name="role"
              >
                <Select>
                  <Option value="user">Simple utilisateur</Option>
                  <Option value="admin">Admin</Option>
                </Select>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Champ obligatoire" }]}
                span={8}
                label="Entité"
                name="entity"
              >
                <Select
                  showSearch
                  value={Entitys.length > 0 && Entitys[0].title}
                  style={{ width: "100%" }}
                  dropdownRender={(menu) => (
                    <div>
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={entityToAdd}
                          onChange={(e) => setEntityToAdd(e.target.value)}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={(e) => entityToAdd != "" && addItemEntity()}
                        >
                          <PlusOutlined /> Nouveau
                        </a>
                      </div>
                      {menu}
                    </div>
                  )}
                >
                  {Entitys.map((entity, i) => (
                    <Option value={entity.title} key={i}>
                      {entity.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="changeDate"
                label="A le droit de changer la date du bl"
              >
                <Switch
                  checked={state.changeDate}
                  onChange={(e) => setState({ ...state, changeDate: e })}
                />
              </Form.Item>
              <Form.Item
                name="removeDuplicata"
                label="A le droit de supprimer le duplicata bl"
              >
                <Switch
                  checked={state.removeDuplicata}
                  onChange={(e) => setState({ ...state, removeDuplicata: e })}
                />
              </Form.Item>
              <Form.Item
                name="removeReglement"
                label="A le droit de supprimer un réglement"
              >
                <Switch
                  checked={state.removeReglement}
                  onChange={(e) => setState({ ...state, removeReglement: e })}
                />
              </Form.Item>
              <Form.Item name="caisse" label="Caisse">
                <Switch
                  checked={state.caisse}
                  onChange={(e) => setState({ ...state, caisse: e })}
                />
              </Form.Item>
              <Form.Item name="inventory" label="Inventaire">
                <Switch
                  checked={state.inventory}
                  onChange={(e) => setState({ ...state, inventory: e })}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </Modal>
  );
};

export default UserModal;
