import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Typography,
  Select,
  Spin,
  Modal,
  Descriptions,
  Input,
  Statistic,
  InputNumber,
} from "antd";
import {
  RightOutlined,
  LeftOutlined,
  PlusSquareOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { OutTable, ExcelRenderer } from "react-excel-renderer";

import React, { useEffect, useState } from "react";
import ls from "localstorage-ttl";
import "../../../Styles/TwoTables.scss";
import Tecdoc from "../../../Utils/Tecdoc";
import ClientModal from "../../Vente/Clients/ClientModal";
import Article from "../../../Models/Article";
import {
  toAddOrUpdateArticle,
  toGetArticlesFromRefs,
} from "../../Gestions/Api";
import {
  errorMsg,
  successMsg,
  toFormatArticleClassifications,
} from "../../../Utils/Utils";
import ArticleModal from "../../Gestions/ArticleModal";
import history from "../../../Utils/History";
import { connect } from "react-redux";
import {
  addItem,
  addItemInternational,
  clearItems,
  setProvider,
  setItemsState,
  setAchat,
  setFODEC,
} from "../../../Actions/ItemsSelectionAtions";
import ItemsSelection from "../../../Components/ItemsSelection";
import { toCreateBR, toGetBonReception, toGetProviderList } from "../APIs";
import ProviderModal from "../Fournisseurs/ProviderModal";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import localization from "moment/locale/fr";
import { DataExistsException } from "../../../DataExitsException";
import { toFormatArticle } from "../../../Utils/Utils";

moment.updateLocale("fr", localization);
const { Option } = Select;

const BonReceptionInternational = ({ bonreception, AppStore, ...props }) => {
  const { id } = useParams();
  const [provider, setProvider] = useState(null);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [articleDetails, setArticleDetails] = useState({
    title: "",
    reference: "",
    marque: "",
    type: "Origine",
    categorie: "",
  });

  const [coefficient, setCoefficient] = useState(0);
  const [open, setOpen] = useState(false);

  const [activeTable, setActiveTable] = useState(1);
  const [reference, setReference] = useState("");
  const [providers, setProviders] = useState(null);
  const [modalFile, setModalFile] = useState(false);
  const [marge, setMarge] = useState(-1);
  const [checked, setChecked] = useState(false);
  const [importedLines, setImportedLines] = useState([]);
  const [suffix, setSuffix] = useState("");
  const [savingState, setSavingState] = useState({
    saving: false,
    print: false,
  });
  const [form] = Form.useForm();

  const [state, setState] = useState({
    createModalVisible: false,
    providerForm: {
      id: -1,
      s__email: "",
      s__fax: "",
      s__matricule: "",
      s__nom: "",
      s__nomInCharge: "",
      s__phone: "",
      s__phoneInCharge: "",
      s__register: "",
    },
    readonly: false,
  });

  useEffect(() => {
    props.clearItems();

    AppStore.setFirst(0);
    AppStore.setSecond(25);

    if (null != id) {
      toGetBonReception(id).then((order) => {
        const orderToUpdate = {
          id: order.id,
          total: order.d__total,
          discount: order.d__discount,
          s__blProvider: order.s__blProvider,
          s__blDate: order.s__blDate,
          items: [],
          provider: order.provider,
        };
        form.setFieldsValue({
          blFournisseur: order.s__blProvider,
          blDate: moment(order.s__blDate),
        });
        for (let item of order.items) {
          orderToUpdate.items.push({
            ...item,

            ref: item.article.s__reference,
            titre: item.article.s__secondTitle,
            quantity: item.d__quantity,
            marge: item.d__marge,
            uPriceHt: item.d__unitPriceHT,
            uPriceTTC: item.d__unitPriceTTC,
            total: item.d__total,
            discount: item.d__discount,
            prix_achat: item.article.l__prixAchatTTC,
            prix_unitaire: item.article.l__prixVenteHT,
            prix_unitaireTTC: item.article.l__prixVenteTTC,
            prix_unitaire_gros: item.article.l__prixVenteGrosHT,
            prix_unitaire_grosTTC: item.article.l__prixVenteGrosTTC,
            tva: item.article.tva.title,
          });
        }

        props.setBRToUpdate(orderToUpdate);
      });
    }
    props.setAchat(true);
    if (null != ls.get("providers")) {
      setProviders(ls.get("providers"));
    }
    toGetProviderList().then((data) => {
      setProviders(data);
      const provider = data.filter((el) => el.s__nom === "importation")[0];
      chooseProvider(provider.s__nom, { data: provider });
    });
  }, [id]);

  const saveProvider = (provider) => {
    if (state.readonly) return;

    setProviders(providers.concat(provider));
    chooseProvider(provider.s__nom, { data: provider });
    setState({ createModalVisible: false, readonly: false, provider: null });
  };

  const onSelectChange = (e) => {
    var p = providers.filter(
      (a) => a.s__nom == document.getElementById("input").value
    );
    if (p.length > 0) {
      props.setProvider(p[0]);
      setProvider(p);
    } else {
      props.setProvider(null);
      setProvider(null);
    }
  };

  const saveBR = async (active) => {
    var toedit = false;
    setSavingState({ saving: true });
    toCreateBR(
      bonreception.provider,
      bonreception.items,
      bonreception.discount,
      bonreception.total,
      "",
      "",
      false,
      checked,
      toedit,
      id,
      active,
      false
    )
      .then(() => history.push("/achat/reception/list"))
      .catch(() => {
        setSavingState({ saving: false });
      });
  };

  const switchTable = () => {
    activeTable === 1 ? setActiveTable(2) : setActiveTable(1);
  };

  const addFromTecdoc = async (article) => {
    var a = new Article();
    a.titre = article.fullName;
    a.reference = article.ref;
    a.marque = article.brand.name;
    a.type = "Adaptable";
    a.categorie = article.fullName;
    a.data = toFormatArticleClassifications(article);
    a.referenceTecdoc = article.ref;
    a.fromTecdoc = true;

    setArticleDetails(a);

    setCreateModalVisible(true);
  };

  const saveArticle = async (id, article) => {
    try {
      var newArticle = await toAddOrUpdateArticle(id, article);
      if (id === -1) {
        setReference(newArticle.s__reference);
        successMsg("Article créé avec succés");
      }
      setCreateModalVisible(false);
    } catch (e) {
      if (e instanceof DataExistsException) {
        errorMsg("L'article existe déja");
      } else {
        errorMsg("Une erreur est survenue");
      }
    }
  };

  const chooseProvider = (providerName, option) => {
    if (providerName === "0")
      setState({
        readonly: false,
        createModalVisible: true,
        providerForm: {
          id: -1,
          s__email: "",
          s__fax: "",
          s__matricule: "",
          s__nom: "",
          s__nomInCharge: "",
          s__phone: "",
          s__phoneInCharge: "",
          s__register: "",
        },
      });
    else {
      props.setProvider(option.data);
      setProvider(option.data);
    }
  };

  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };
  const openReadOnlyModal = () =>
    setState({
      providerForm: provider,
      createModalVisible: true,
      readonly: true,
    });

  const updateMarge = (value) => {
    setMarge(value);
  };

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        errorMsg("Error lors de l'importation du fichier!");
      } else {
        var refs = [];
        resp.rows.forEach((element) => {
          refs.push(element[0]);
        });

        toGetArticlesFromRefs(refs, suffix).then((data) => {
          for (var i = 0; i < data.length; i++) {
            if (resp.rows[i].length > 0) {
              data[i].ref = resp.rows[i][0];
              data[i].title = resp.rows[i][1];
              data[i].quantity = resp.rows[i][2];

              var number =
                typeof resp.rows[i][3] == "number"
                  ? resp.rows[i][3]
                  : Number(resp.rows[i][3].replace(/[^0-9\.-]+/g, ""));

              data[i].unitPrice = number;
              data[i].total =
                typeof resp.rows[i][4] == "number"
                  ? resp.rows[i][4]
                  : resp.rows[i][4].replace(/[^0-9\.-]+/g, "");
            }
          }
          setImportedLines(data);
        });
      }
    });
  };

  const rightColHeader = (
    <Row justify="space-between">
      <Col md={12}>
        <Typography.Title style={{ marginLeft: "10px" }} level={5}>
          Bon de réception international
        </Typography.Title>
      </Col>

      <Form>
        <Button
          type="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          Calculer le coefficient
        </Button>

        <Button
          type="primary"
          style={{ marginLeft: "10px" }}
          disabled={coefficient == 0}
          onClick={() => {
            setModalFile(true);
            setSuffix("");
            setImportedLines([]);
          }}
        >
          Ajouter depuis un fichier
        </Button>
      </Form>
      <Typography.Title style={{ marginLeft: "10px" }} level={5}>
        Coefficient: {coefficient}
      </Typography.Title>

      {state.clientForm && (
        <ClientModal
          clientForm={state.clientForm}
          visible={state.createModalVisible}
          onCancel={() =>
            setState({
              createModalVisible: false,
              clientForm: null,
              readonly: false,
            })
          }
          onClientSaved={null}
          readonly={state.readonly}
        />
      )}
    </Row>
  );

  const toCalculateCoefficient = () => {
    var i__coefficient = 0;
    form.validateFields().then((values) => {
      i__coefficient =
        (values.marchandise * values.taux +
          values.cout +
          values.diouane -
          values.tvaDiouane) /
        values.marchandise;
      setCoefficient(i__coefficient);
    });
  };

  const toAddImportedArticles = () => {
    importedLines
      .filter((e) => e.exist)
      .map((article) => {
        article.json.l__prixAchatHT = coefficient * article.unitPrice;
        article.json.quantity = article.quantity;
        const selectedArticle = toFormatArticle(article.json);
        props.addItemInternational(selectedArticle);
      });
  };

  return (
    <>
      <Modal
        title="Calcul prix"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={[]}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Valeur de la marchandise en dollar"
            name="marchandise"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "200px" }}></InputNumber>
          </Form.Item>
          <Form.Item
            label="Taux de conversion"
            name="taux"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "200px" }}></InputNumber>
          </Form.Item>
          <Form.Item
            label="Coût de transport"
            name="cout"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "200px" }}></InputNumber>
          </Form.Item>
          <Form.Item
            label="Diouane"
            name="diouane"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "200px" }}></InputNumber>
          </Form.Item>

          <Form.Item
            label="TVA Diouane"
            name="tvaDiouane"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "200px" }}></InputNumber>
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button type="primary" onClick={() => toCalculateCoefficient()}>
              Calculer le coefficient
            </Button>
          </div>
          <Statistic
            title={"Le coffiecient est"}
            value={coefficient}
            precision={3}
          />
        </Form>
      </Modal>

      {modalFile && (
        <Modal
          title="Ajouter depuis un fichier"
          visible={modalFile}
          className="smtv-modal"
          width={"100vh"}
          onCancel={() => setModalFile(false)}
          footer={
            <>
              <Button
                type="primary"
                onClick={() => {
                  setModalFile(false);
                  toAddImportedArticles();
                }}
              >
                Valider
              </Button>
              <Button
                onClick={() => {
                  setModalFile(false);
                }}
              >
                Annuler
              </Button>
            </>
          }
        >
          <Row style={{ marginTop: "1vh" }}>
            <Col span="8">
              <input
                type="file"
                onChange={(e) => fileHandler(e)}
                style={{ padding: "10px" }}
              />
            </Col>
            <Col span="8">
              <Input
                placeholder="suffix"
                onChange={(e) => setSuffix(e.target.value)}
              ></Input>
            </Col>
          </Row>

          <Descriptions column={2}>
            <Descriptions.Item label="Nombre d'article">
              <Typography.Title level={5}>
                {importedLines.length} article(s)
              </Typography.Title>
            </Descriptions.Item>

            <Descriptions.Item label="Nombre d'article non trouvé(s)">
              <Typography.Title level={5}>
                {importedLines.filter((el) => !el.exist).length} article(s)
              </Typography.Title>
            </Descriptions.Item>
          </Descriptions>

          <table className="ant-table" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th className="ant-table-cell">Réf</th>
                <th className="ant-table-cell">Nom</th>
                <th className="ant-table-cell">Quantité</th>
                <th className="ant-table-cell">Prix unitaire</th>
                <th className="ant-table-cell">Total</th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {importedLines.map((el) => (
                <tr style={{ backgroundColor: !el.exist && "#ef5350" }}>
                  <td className="ant-table-cell">{el.ref}</td>
                  <td className="ant-table-cell">{el.title}</td>
                  <td className="ant-table-cell">{el.quantity}</td>
                  <td className="ant-table-cell">{el.unitPrice}</td>
                  <td className="ant-table-cell">{el.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
      <Row style={{ height: "100%" }} className="two-tables-row">
        <Col lg={12} md={24} className={"articles-list"}>
          <Card>
            <Tecdoc
              addFromTecdoc={addFromTecdoc}
              displayTitle={true}
              addItem={props.addItem}
              reference={reference}
              achat={true}
            />
          </Card>
        </Col>
        <Col
          lg={12}
          md={24}
          className={"selection-list" + (activeTable === 2 ? " active" : "")}
        >
          <Card title={rightColHeader} style={{ minHeight: "100%" }}>
            <ItemsSelection displayMarge={true} getMarge={updateMarge} />
            {bonreception.items && bonreception.items.length > 0 && (
              <div className="selection-footer">
                <Row justify="end" gutter={8}>
                  <Col xl={6} lg={6}>
                    <Button
                      onClick={() => saveBR(false)}
                      block
                      type="dashed"
                      style={{ marginRight: "10px" }}
                      disabled={savingState.saving}
                    >
                      <span
                        style={{
                          marginRight: 10,
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: !savingState.print
                            ? "text-top"
                            : "baseline",
                        }}
                      >
                        Enregister brouillon
                      </span>
                      {savingState.saving && !savingState.print && (
                        <Spin size="small" style={{ marginLeft: 10 }} />
                      )}
                    </Button>
                  </Col>
                  <Col xl={6} lg={6}>
                    <Button
                      onClick={() => saveBR(true)}
                      block
                      type={"primary"}
                      style={{ marginRight: "10px" }}
                      disabled={savingState.saving}
                    >
                      <span
                        style={{
                          marginRight: 10,
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: !savingState.print
                            ? "text-top"
                            : "baseline",
                        }}
                      >
                        Enregister
                      </span>
                      {savingState.saving && !savingState.print && (
                        <Spin size="small" style={{ marginLeft: 10 }} />
                      )}
                    </Button>
                  </Col>
                  <Col xl={6} lg={6}>
                    <Button
                      block
                      onClick={() => saveBR(true)}
                      disabled={savingState.saving}
                      style={{ marginRight: 10 }}
                    >
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: savingState.print
                            ? "text-top"
                            : "baseline",
                        }}
                      >
                        Enregistrer et Imprimer{" "}
                      </span>
                      {savingState.print && (
                        <Spin size="small" style={{ marginLeft: 10 }} />
                      )}
                    </Button>
                  </Col>
                  <Col xl={6} lg={6}>
                    <Button block danger onClick={props.clearItems}>
                      <Link to="/achat/reception">Annuler</Link>
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Card>
        </Col>
      </Row>

      <Button
        className={"table-switch" + (activeTable === 2 ? " left" : "")}
        onClick={switchTable}
      >
        {activeTable === 1 ? <RightOutlined /> : <LeftOutlined />}
      </Button>
      {state.providerForm && (
        <ProviderModal
          providerForm={state.providerForm}
          visible={state.createModalVisible}
          exitCreateModal={() =>
            setState({
              createModalVisible: false,
              providerForm: null,
              readonly: false,
            })
          }
          onProviderSaved={saveProvider}
          readonly={state.readonly}
        />
      )}

      <ArticleModal
        saveArticle={saveArticle}
        details={articleDetails}
        createModalVisible={createModalVisible}
        closeCreateModal={exitCreateModal}
      />
    </>
  );
};

const mapStateToProps = ({ itemSelectionState }) => {
  return {
    bonreception: itemSelectionState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBRToUpdate: (PurshaseOrder) => dispatch(setItemsState(PurshaseOrder)),
    addItem: (item) => dispatch(addItem(item)),
    addItemInternational: (item) => dispatch(addItemInternational(item)),
    setProvider: (provider) => dispatch(setProvider(provider)),
    setAchat: (value) => dispatch(setAchat(value)),
    setFODEC: (value) => dispatch(setFODEC(value)),
    clearItems: () => dispatch(clearItems()),
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BonReceptionInternational);
